<template>
  <fieldset class="mb-6 h-14 relative">
    <label class="block mb-2">
      {{ locale.tags }}:
      <a @click.prevent="toggleArea" title="Hotkey: Ctrl + H" content="Hotkey: Ctrl + H"  v-tippy class="story-form__manage-tags" href="#">
        {{ linkCaption }}
      </a>
    </label>
    <p class="relative">
      <input v-if="editable" v-model="tags" @keydown="triggerEnter" @blur="toggleArea" ref="tags" type="text" class="absolute top-0 -left-1 w-64 p-1" />
      <span v-if="!editable" class="block h-16 overflow-hidden">{{ hashTags }}</span>
      <input type="hidden" v-model="tags" name="story[tag_list]" />
    </p>
  </fieldset>
</template>

<script>
  import _ from "lodash"

  export default {
    props: {
      locale: {
        required: true
      },
      defaultTags: {
        default() {
          return []
        }
      },
      hashTags: {
        default: null
      }
    },
    data() {
      return {
        tags: this.defaultTags.join(" "),
        editable: false
      }
    },
    mounted() {
      this.bindHotkeys()
    },
    beforeDestroy() {
      this,unbindHotkeys()
    },
    watch: {
      editable() {
        if (this.editable == false) {
          this.tags = this.tags.replace('#', '').replace(',', '')
          this.emitTagsUpdate()
        }
      }
    },
    computed: {
      linkCaption() {
        if (this.tags.length) {
          return this.locale.buttons.edit_tags
        } else {
          return this.locale.buttons.add_tags
        }
      }
    },
    methods: {
      bindHotkeys() {
        this._keyListener = (e) => {
          if (e.key === "h" && (e.ctrlKey || e.metaKey)) {
            e.preventDefault()
            this.toggleArea()
          }
        }
        this._boundListener = this._keyListener.bind(this)
        document.addEventListener("keydown", this._boundListener)
      },
      unbindHotkeys() {
        document.removeEventListener("keydown", this._boundListener)
      },
      toggleArea() {
        if (this.editable) {
          this.editable = false
        } else {
          this.editable = true
          this.$nextTick(() => {
            this.$refs.tags.focus()
          })
        }
      },
      triggerEnter(e) {
        if (e.key == "Enter") {
          e.preventDefault()
          this.editable = false
        }
      },
      emitTagsUpdate() {
        this.$emit("refreshTags", _.compact(this.tags.split(" ")))
      }
    }
  }
</script>

<style lang="css">
  .story-form__manage-tags {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #0077B5;
    border-bottom: #0077b5 1px dashed;
  }
</style>
