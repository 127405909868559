// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require.context("../assets", true)

import Vue from 'vue'
import TurbolinksAdapter from "vue-turbolinks"
import TextareaAutosize from "vue-textarea-autosize"
import VueTippy, { TippyComponent } from "vue-tippy"

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Vue.use(TurbolinksAdapter)
Vue.use(TextareaAutosize)
Vue.use(VueTippy)
Vue.component("tippy", TippyComponent)

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

import { library, dom } from "@fortawesome/fontawesome-svg-core"
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons"
import { faPlus, faChevronDown, faCheck, faBug } from "@fortawesome/free-solid-svg-icons"
library.add(faTelegramPlane, faPlus, faChevronDown, faCheck, faBug)
dom.watch()


import Story from '../apps/story.vue'

document.addEventListener('turbo:load', () => {
  const $landingButton = document.getElementById('open-target')
  if ($landingButton != undefined) {
    $landingButton.onclick = function(e) {
      const width = 480
      const left = screen.availLeft + (screen.availWidth - width);

      window.openTargetPopup = window.open(e.target.href, "Sign in with Telegram", `menubar=1,resizable=1, width=${width}, height=640, left=${left}`)
      e.preventDefault()
    }
  }

  const $storyFormParent = document.getElementById('story-form')
  if ($storyFormParent != undefined) {
    let data = $storyFormParent.dataset
    window.storyApp = new Vue({
      el: $storyFormParent,
      render: h => h(Story, {
        props: {
          locale: JSON.parse(data.locale),
          messageAttachmentsLimit: data.limitAttachments,
          messageCharactersLimit: data.limitMessage,
          captionCharactersLimit: data.limitCaption,
          storyTitle: data.storyTitle,
          storyStatus: data.storyStatus,
          storyMode: data.storyMode,
          storyMessages: JSON.parse(data.storyMessages),
          storyTags: JSON.parse(data.storyTags),
          storyErrors: JSON.parse(data.storyErrors)
        }
      })
    })
  }
})

Rails.start()
ActiveStorage.start()

import "../css/application.css"
