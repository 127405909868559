import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { status: String, mode: String, url: String }

  connect () {
    if (this.modeValue == "preview" || this.modeValue == "publish" && this.statusValue == "error") {
      // Reactivate publishing controls
      let story = window.storyApp.$children[0]
      story.inProgress = false
      story.isPreview  = false
    }

    if (this.modeValue == "publish" && this.statusValue == "success") {
      setTimeout(() => { window.location.href = this.urlValue }, 3000)
    }
  }
}
