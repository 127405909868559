<template>
  <div class="flex mb-6" :id="messageId">

    <div class="w-3/4 mr-12">
      <fieldset>
        <textarea-autosize
          v-model="body"
          :name="messageFieldName(index, 'body')"
          :placeholder=locale.body
          class="h-64 w-full text-xl outline-none"
          ref="body"
          @keyup.native="preventOvertyping"
          @focus.native="makeInFocus"
          @blur.native="makeOutFocus"
          :min-height="150"
          :tabindex="index+2"
        />
      </fieldset>
      <fieldset class="mt-3 -mx-1" v-if="previews.length">
        <span v-for="(preview, key) in previews" :key="key" :style="{ backgroundImage: `url(${preview.src})` }" class="story-form__preview inline-block align-top h-28 m-1 relative bg-cover bg-center bg-no-repeat bg-gray-50">
          <b class="absolute bottom-2 right-2 text-sm text-red-500 cursor-pointer" v-if="previewUploaded(preview)" @click="removeThumbnail(preview)">
            x
          </b>
        </span>
      </fieldset>
      <fieldset class="mt-6">
        <div class="border-t border-dashed border-gray-300" />
        <div :class="uppyProgressClassname" />
      </fieldset>
    </div>

    <div class="w-1/4 relative">
      <div class="absolute left-0 bottom-0 text-sm text-gray-400">
        <p class="mb-2">
          {{ this.locale.characters }}: {{ charactersCount }} of {{ charactersLimit }} <a href="#">(?)</a>
        </p>
        <p class="mb-2" v-if="index != 0">
          <a class="story-form__link" title="Hotkey: Ctrl + R" content="Hotkey: Ctrl + R" v-tippy href="#" @click.prevent="removeMessage">
            {{ this.locale.buttons.remove_message }}
          </a>
        </p>
        <p class="mb-2" v-show="!uppyVisibility">
          <span :class="uppyInputClassname" />
        </p>
        <ul v-if="errors" class="text-xs italic text-pink-600">
          <li v-for="(error, index) in errors" :key="index">
            {{ error }}
          </li>
        </ul>
      </div>
    </div>

    <span v-for="upload in uploads" :key="upload.body.id">
      <input v-if="upload.body.storage == 'cache'" :name="uploadFieldName(index, upload.body)" :value="JSON.stringify(upload.body)" type="hidden"  />
    </span>
    <span v-for="destroy in destroys" :key="destroy">
      <input :name="destroyFieldName(index, destroy, '_destroy')" value="1" type="hidden" />
      <input :name="destroyFieldName(index, destroy, 'id')" :value="destroy" type="hidden" />
    </span>
    <input :value="defaultId" :name="messageFieldName(index, 'id')" v-if="defaultId" type="hidden" />

  </div>
</template>

<script>
  import _ from "lodash"
  import u from "umbrellajs"
  import Uppy from "@uppy/core"
  import FileInput from "@uppy/file-input"
  import StatusBar from "@uppy/status-bar"
  import ThumbnailGenerator from "@uppy/thumbnail-generator"
  import XHRUpload from "@uppy/xhr-upload"

  export default {
    props: {
      locale: {
        required: true
      },
      index: {
        required: true
      },
      title: {
        default: null
      },
      hashTags: {
        default: null
      },
      defaultId: {
        default: null
      },
      defaultBody: {
        default: null
      },
      defaultPreviews: {
        default() {
          return []
        }
      },
      defaultUploads: {
        default() {
          return []
        }
      },
      defaultMessageErrors: {
        default() {
          return []
        }
      },
      messageAttachmentsLimit: {
        required: true,
        default: 0
      },
      messageCharactersLimit: {
        required: true,
        default: 0
      },
      captionCharactersLimit: {
        required: true,
        default: 0
      }
    },
    data() {
      return {
        body: this.defaultBody,
        previews: this.defaultPreviews,
        uploads: this.defaultUploads,
        messageErrors: this.defaultMessageErrors,
        inFocus: false,
        errors: [],
        destroys: [],
        messageOverLimited: false,
        activeColor: 'yellow',
        fontSize: 13
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.$refs.body.$el.focus()
        this.synchBodies()
        this.synchPreviews()
        this.synchUploads()
        this.mountSchedule()
        this.bindUppy()
      })
    },
    beforeDestroy() {
      this.unbindHotkeys()
    },
    watch: {
      inFocus() {
        if (this.inFocus == true) {
          this.bindHotkeys()
        } else {
          this.unbindHotkeys()
        }
      },
      title() {
        this.preventOvertyping()
      },
      body() {
        this.synchBodies()
      },
      hashTags() {
        this.preventOvertyping()
      },
      previews() {
        this.synchPreviews()
        this.preventOvertyping()
      },
      uploads() {
        this.synchUploads()
      }
    },
    computed: {
      messageId() {
        return `message__${this._uid}`
      },
      titleLenght() {
        if (this.index == 0 && this.title) {
          return `*${this.title}*\n`.length
        } else {
          return 0
        }
      },
      hashTagsLenght() {
        if (this.index == 0 && this.hashTags) {
          return `${this.hashTags}\n`.length
        } else {
          return 0
        }
      },
      bodyLength() {
        if (this.body) {
          if (this.titleLenght > 0 || this.hashTagsLenght > 0) {
            return `\n${this.body}`.length
          } else {
            return this.body.length
          }
        } else {
          return 0
        }
      },
      charactersCount() {
        return this.titleLenght + this.hashTagsLenght + this.bodyLength
      },
      charactersLimit() {
        return this.previews.length ? this.captionCharactersLimit : this.messageCharactersLimit
      },
      uppyClassname() {
        return `Uppy-${this._uid}`
      },
      uppyInputClassname() {
        return `${this.uppyClassname}__input`
      },
      uppyProgressClassname() {
        return `${this.uppyClassname}__progress`
      },
      previewedIds() {
        return _.map(this.previews, "file.id")
      },
      uploadedIds() {
        return _.map(this.uploads, "file.id")
      },
      uppyVisibility() {
        return this.previews.length >= this.messageAttachmentsLimit
      }
    },
    methods: {
      bindUppy() {
        this.uppyOne = new Uppy({
          debug: false,
          autoProceed: true,
          restrictions: {
            maxNumberOfFiles: this.messageAttachmentsLimit,
            maxFileSize: 7000000,
            allowedFileTypes: ['image/*']
          }
        })

        this.uppyOne
          .use(FileInput, {
            target: `.${this.uppyInputClassname}`,
            pretty: true,
            locale: {
              strings: {
                chooseFiles: this.locale.buttons.attach_files
              }
            }
          })
          .use(XHRUpload, { endpoint: "/upload" })
          .use(StatusBar, {
            target: `.${this.uppyProgressClassname}`,
            hideUploadButton: true,
            hideCancelButton: true
          })
          .use(ThumbnailGenerator, {
            thumbnailWidth: 500,
            waitForThumbnailsBeforeUpload: false
          })
          // TODO: Observe Uppy files state instead and synch on update accordingly?
          .on("thumbnail:generated", (file, preview) => {
            this.previews.push({ file: file, src: preview })
          })
          .on("upload-success", (file, response) => {
            this.uploads.push({ file: file, body: response.body })
          })
          .on("restriction-failed", (file, message) => {
            this.errors = []
            this.errors.push(message)
          })
          .on("upload", (data) => {
            this.errors = []
          })

          u(".uppy-FileInput-btn").attr("title", "Hotkey: Ctrl + U")
      },
      bindHotkeys() {
        this._keyListener = (e) => {
          // Ctrl + R to remove message
          if (e.key === "r" && (e.ctrlKey || e.metaKey)) {
            e.preventDefault()
            this.removeMessage()
          }
          // Ctrl + U to trigger Uppy files selector
          if (e.key === "u" && (e.ctrlKey || e.metaKey)) {
            e.preventDefault()
            u(`#message__${this._uid} .uppy-FileInput-btn`).trigger("click")
          }
        }
        this._boundListener = this._keyListener.bind(this)
        document.addEventListener('keydown', this._boundListener)
      },
      unbindHotkeys() {
        document.removeEventListener("keydown", this._boundListener)
      },
      makeInFocus() {
        this.inFocus = true
      },
      makeOutFocus() {
        this.inFocus = false
      },
      preventOvertyping() {
        if (this.charactersCount > this.charactersLimit) {
          let charactersLimit = this.charactersLimit - this.titleLenght - this.hashTagsLenght

          if (!this.messageOverLimited) {
            this.messageOverLimited = true
            this.$emit("triggerMessageOverLimit", this.body.substr(charactersLimit, this.bodyLength), this.index)
          }

          this.body = this.body.substr(0, charactersLimit)
        }
      },
      previewUploaded(preview) {
        return _.includes(this.uploadedIds, preview.file.id)
      },
      messageFieldName(messageIndex, feildName) {
        return `story[messages_attributes][${messageIndex}][${feildName}]`
      },
      uploadFieldName(messageIndex, upload) {
        return `story[messages_attributes][${messageIndex}][attachments_attributes][${upload.id}][image]`
      },
      destroyFieldName(messageIndex, fileIndex, fieldName) {
        return `story[messages_attributes][${messageIndex}][attachments_attributes][${fileIndex}][${fieldName}]`
      },
      findThumbnailIn(arr, preview) {
        return _.find(arr, (item) => { return item.file.id == preview.file.id })
      },
      mountSchedule() {
        let $el = u("#story-form__schedule")
        u("#story-form__schedule-dom").append($el.clone().first())
        $el.remove()
      },
      synchBodies() {
        this.$emit("synchMessageBodies", this.index, this.body)
      },
      synchPreviews() {
        this.$emit("extendCollection", this.index, "previews", this.previews)
      },
      synchUploads() {
        this.$emit("extendCollection", this.index, "uploads", this.uploads)
      },
      removeThumbnail(thumbnail) {
        // From Uppy
        this.uppyOne.removeFile(thumbnail.file.id)
        // From previews
        let previewItem = this.findThumbnailIn(this.previews, thumbnail)
        this.$delete(this.previews, _.indexOf(this.previews, previewItem))
        // From uploads
        let uploadItem = this.findThumbnailIn(this.uploads, thumbnail)
        this.$delete(this.uploads, _.indexOf(this.uploads, uploadItem))
        // For good
        if (uploadItem.body.storage == "store") {
          this.destroys.push(uploadItem.file.id)
        }
      },
      removeMessage() {
        if (this.index != 0) {
          this.$emit("removeMessage", this.index)
        }
      }
    }
  }
</script>

<style lang="css">
  .story-form__link {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .story-form__link, .uppy-FileInput-btn {
    color: #0077B5;
    border-bottom: #0077b5 1px dashed;
  }
  .story-form__preview {
    width: 9.2rem;
  }
  .story-form__preview b {
    display: none;
  }
  .story-form__preview:hover b {
    display: block;
  }
</style>
