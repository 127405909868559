<template>
  <div>
    <ul class="mb-6 italic text-pink-600" v-if="errors.length > 0">
      <li class="mb-2" v-for="(error, index) in errors" :key="index">
        {{ error }}
      </li>
    </ul>

    <div class="flex">
      <div class="w-3/4 mr-12">
        <fieldset class="-ml-2 mb-6">
          <input v-model="title" name="story[title]" :placeholder="locale.title" class="p-2 w-full text-2xl font-bold outline-none" tabindex="1" />
        </fieldset>
      </div>
      <div class="w-1/4 relative">
        <div class="absolute top-0 left-0 z-10 text-sm text-gray-400">
          <fieldset class="mb-6">
            <label class="inline-block mr-2 mb-1">{{ locale.status }}</label>
            <select v-model="status" name="story[status]">
              <option v-for="option in statusOptions" :value="option.value" :key="option.value">
                {{ option.title }}
              </option>
            </select>
          </fieldset>
          <fieldset class="mb-6">
            <label class="inline-block mr-2">{{ locale.mode }}</label>
            <label v-for="option in modeOptions" :key="option.value">
              <input name="story[mode]" type="radio" v-model="mode" :value="option.value" />
              {{ option.title }}
            </label>
          </fieldset>
          <Tags
            :locale="locale"
            :defaultTags="tags"
            :hashTags="hashTags"
            @refreshTags="refreshTags"
          />
          <fieldset v-if="sideButtonVisibility">
            <input type="submit" class="button button--tiny mb-1 mr-2" :value="saveButtonCaption" :disabled="disabled" />
            <button type="submit" name="preview" class="disabled:opacity-50 text-black border-b border-dashed border-black" @click="makePreview" :disabled="disabled">
              {{ locale.buttons.preview }}
            </button>
            <p v-if="inProgress" class="mt-3 animate-pulse">
              <i class="fab fa-lg fa-telegram-plane fa-spin mr-2" />
              {{ locale.in_progress }}
            </p>
          </fieldset>
        </div>
      </div>
    </div>

    <Message
      v-for="(message, index) in messages"
      :key="message.key"
      :locale="locale"
      :index="index"
      :title="title"
      :hashTags="hashTags"
      :defaultId="message.id"
      :defaultBody="message.body"
      :defaultPreviews="message.previews"
      :defaultUploads="message.uploads"
      :defaultMessageErrors="errors"
      :messageAttachmentsLimit="messageAttachmentsLimit"
      :messageCharactersLimit="messageCharactersLimit"
      :captionCharactersLimit="captionCharactersLimit"
      @removeMessage="removeMessage"
      @synchMessageBodies="synchMessageBodies"
      @triggerMessageOverLimit="appendMessage"
      @extendCollection="extendCollection"
    />
    <div class="flex">
      <div class="w-3/4 mr-12">
        <div>
          <div id="story-form__schedule-dom" class="text-sm text-gray-400" :class="scheduleVisibility" />
          <input type="submit" class="button" :value="saveButtonCaption" :disabled="disabled" />
          <button type="submit" name="preview" class="ml-3 mt-3 disabled:opacity-50 border-b border-dashed border-black" @click="makePreview" :disabled="disabled">
            {{ locale.buttons.preview }}
          </button>
        </div>
        <div v-if="inProgress" class="mt-3 animate-pulse">
          <i class="fab fa-lg fa-telegram-plane fa-spin mr-2" />
          {{ locale.in_progress }}
        </div>
      </div>
      <div class="w-1/4 text-sm text-gray-400">
        <fieldset>
          <p class="mb-1">
            {{ this.locale.messages }}: {{ messages.length }}
          </p>
          <p>
            <a class="story-form__add-message" title="Hotkey: Ctrl + M" content="Hotkey: Ctrl + M" v-tippy href="#" @click.prevent="addMessage">
              {{ this.locale.buttons.add_message }}
            </a>
          </p>
        </fieldset>
      </div>
    </div>

    <input name="story[time_zone_offset]" type="hidden" :value="timeZoneOffset" />
    <input name="request_mode" type="hidden" value="preview" v-if="isPreview" />
  </div>
</template>

<script>
  import _ from "lodash"
  import u from "umbrellajs"
  import Tags from "./tags"
  import Message from "./message"

  export default {
    components: {
      Tags,
      Message
    },
    props: {
      locale: {
        required: true
      },
      messageAttachmentsLimit: {
        required: true,
        default: 0
      },
      messageCharactersLimit: {
        required: true,
        default: 0
      },
      captionCharactersLimit: {
        required: true,
        default: 0
      },
      storyTitle: {
        default: null
      },
      storyStatus: {
        default: "draft"
      },
      storyMode: {
        default: "story"
      },
      storyMessages: {
        default: [{ body: null, attachments: [] }]
      },
      storyTags: {
        default: []
      },
      storyErrors: {
        default: []
      }
    },
    data() {
      return {
        title: this.storyTitle,
        tags: this.storyTags,
        status: this.storyStatus == "preview" ? "draft" : this.storyStatus,
        mode: this.storyMode,
        messages: this.storyMessages,
        inProgress: false,
        isPreview: false,
        errors: this.storyErrors,
        messageBodies: [],
        statusOptions: [
          { title: this.locale.options.status.published, value: "published" },
          { title: this.locale.options.status.draft, value: "draft" },
          { title: this.locale.options.status.scheduled, value: "scheduled" }
        ],
        modeOptions: [
          { title: this.locale.options.mode.story, value: "story" },
          { title: this.locale.options.mode.thread, value: "thread" }
        ],
        previews: [],
        uploads: []
      }
    },
    mounted() {
      this.bindHotkeys()
      this.bindSubmitObserver()
    },
    beforeDestroy() {
      this.unbindHotkeys()
    },
    computed: {
      disabled() {
        if (this.inProgress) {
          return "disabled"
        } else {
          if (this.messageBodiesLength > 0) {
            return false
          } else if (_.flattenDeep(this.previews).length > 0 && this.previewsPreloaded) {
            // TODO: There shouldn't be `flattenDeep` ideally
            return false
          } else {
            return "disabled"
          }
        }
      },
      saveButtonCaption() {
        switch (this.status) {
          case "draft": return this.locale.buttons.draft
          case "published": return this.locale.buttons.publish
          case "scheduled": return this.locale.buttons.schedule
        }
      },
      sideButtonVisibility() {
        if (this.messageBodies.length > 0) {
          let firstMessagePreview = this.previews[0]
          let firstMessageBody = this.messageBodies[0]
          let sidebarVisible = firstMessagePreview.length || (firstMessageBody && firstMessageBody.length > 500)

          return sidebarVisible
        }
      },
      scheduleVisibility() {
        return this.status == "scheduled" ? "visible" : "hidden"
      },
      hashTags() {
        if (this.tags.length > 0) {
          return _.map(this.tags, (tag) => { return `#${tag}` }).join(" ")
        }
      },
      previewsPreloaded() {
        return _.isEqual(this.previews, this.uploads)
      },
      messageBodiesLength() {
        return _.sum(_.map(_.compact(this.messageBodies), message => message.length))
      },
      timeZoneOffset() {
        return -1 * new Date().getTimezoneOffset()
      }
    },
    methods: {
      // #TODO: Extract bindings as a module
      bindHotkeys() {
        this._keyListener = (e) => {
          if (e.key === "m" && (e.ctrlKey || e.metaKey)) {
            e.preventDefault()
            this.addMessage()
          }
        }
        this._boundListener = this._keyListener.bind(this)
        document.addEventListener("keydown", this._boundListener)
      },
      unbindHotkeys() {
        document.removeEventListener("keydown", this._boundListener)
      },
      bindSubmitObserver() {
        let _that = this
        u('form.remember-input').on('submit', () => {
          _that.inProgress = true
        })
      },
      messageKey() {
        return _.uniqueId("message__")
      },
      removeMessage(messageIndex) {
        this.$delete(this.messages, messageIndex)
      },
      addMessage() {
        this.messages.push({ body: null, key: this.messageKey() })
      },
      refreshTags(tags) {
        this.tags = tags
      },
      appendMessage(body, index) {
        let item = { body: body, key: this.messageKey() }
        this.messages.splice(index + 1, 0, item)
      },
      extendCollection(messageId, collectionType, collection) {
        this.$set(this[collectionType], messageId, _.sortBy(_.map(collection, "file.id")))
      },
      synchMessageBodies(messageIndex, messageBody) {
        this.$set(this.messageBodies, messageIndex, messageBody)
      },
      makePreview() {
        this.isPreview = true
      }
    }
  }
</script>

<style lang="css">
  .story-form__add-message {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #0077B5;
    border-bottom: #0077b5 1px dashed;
  }
</style>
