import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { url: String }
  connect () {
    var popup = window.openTargetPopup
    if (popup != undefined) { popup.close() }

    setTimeout(() => { window.location.href = this.urlValue }, 1500)
  }
}
